<template>
    <div id="sidebar-button" class="flex flex-col">
        <!--        <button-->
        <!--            type="button"-->
        <!--            @click="onPress"-->
        <!--            class="text-white ring-0 focus:ring-0 focus:bg-white focus:text-black  hover:text-gray-900 hover:bg-gray-50 p-2 py-6 text-sm font-medium rounded-md"-->
        <!--        >-->
        <!--            {{ title }}-->
        <!--        </button>-->
        <router-link
            :to="pageName"
            active-class="text-gray-900 ring-0 bg-white p-2 py-6 text-xs desktop:text-sm font-medium rounded-md"
            class="relative text-white ring-0 focus:ring-0 p-2 py-6 text-xs desktop:text-sm font-medium rounded-md flex justify-center items-center"
        >
            <span class="absolute left-10 desktop:left-16"><slot></slot></span>
            <span>{{ title }}</span>
            <span
                class="absolute rounded-full bg-red-500 mx-3 w-5 h-5 shadow-lg text-white font-bold"
                v-if="pendingCount > 0 && pendingCount < 100"
            >
                {{ pendingCount }}
            </span>
            <span v-if="pendingCount > 100"> 99+</span></router-link
        >
    </div>
</template>

<script>
export default {
    name: "NavButton",
    props: {
        title: String,
        pageName: String,
        pendingCount: Number
    },
    emits: ["onPress"],
    methods: {
        onPress() {
            this.$emit("onPress")
        }
    }
}
</script>

<style scoped></style>
