<template>
    <div
        ref="header"
        class="relative mt-8 z-10 flex-shrink-0 flex h-16 bg-white lg:border-none shadow-md"
        :class="{ 'rounded-t-3xl': sticky, 'shadow-none': sticky }"
    >
        <div
            class="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-6xl xl:max-w-full lg:mx-auto lg:px-8 items-center"
        >
            <div class="flex-1 flex">
                <form
                    class="w-full flex md:ml-0 "
                    ref="search"
                    @submit="search"
                    @submit.prevent
                >
                    <label for="search_field" class="sr-only ">Search</label>
                    <div
                        class="flex relative w-11/12 lg:w-full text-gray-400 focus-within:text-gray-600 items-center"
                    >
                        <!--                        <div-->
                        <!--                            class="absolute items-center pointer-events-none mr-3"-->
                        <!--                            aria-hidden="true"-->
                        <!--                        >-->
                        <!--                            &lt;!&ndash; Heroicon name: solid/search &ndash;&gt;-->
                        <!--                            <svg-->
                        <!--                                class="h-5 w-5"-->
                        <!--                                xmlns="http://www.w3.org/2000/svg"-->
                        <!--                                viewBox="0 0 20 20"-->
                        <!--                                fill="currentColor"-->
                        <!--                                aria-hidden="true"-->
                        <!--                            >-->
                        <!--                                <path-->
                        <!--                                    fill-rule="evenodd"-->
                        <!--                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"-->
                        <!--                                    clip-rule="evenodd"-->
                        <!--                                />-->
                        <!--                            </svg>-->
                        <!--                        </div>-->
                        <input
                            id="search_field"
                            name="search_field"
                            autocomplete="off"
                            class="block border border-gray-200 rounded-md w-full mr-3 pl-3 pr-3 py-1 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 text-xs lg:text-sm"
                            :placeholder="$t('nav.search')"
                            v-bind:value="searchTerm"
                            @input="searchTerm = $event.target.value"
                        />
                        <button
                            type="button"
                            class="absolute right-12  ml-2"
                            @click="search"
                        >
                            <svg
                                class="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </button>
                        <button
                            type="button"
                            @click="clearInput"
                            class="absolute right-6 text-2xl  ml-2"
                        >
                            ×
                        </button>
                    </div>
                </form>
            </div>
            <div class="w-1/5 desktop:w-1/6 flex justify-center">
                <!-- Profile dropdown -->
                <div class=" relative">
                    <div>
                        <button
                            @click="dropMenu = !dropMenu"
                            class="max-w-xs overflow-y-auto content-between bg-white rounded-full flex items-center text-xs lg:text-sm focus:outline-none desktop:p-2 lg:rounded-md lg:hover:bg-gray-50"
                            id="user-menu"
                            aria-haspopup="true"
                        >
                            <img
                                class="h-7 w-7 rounded-full inline-block mr-3 flex"
                                :src="
                                    picture
                                        ? picture
                                        : this.$auth.user.value.picture
                                "
                                alt="user's profile picture"
                            />
                            <span
                                class="desktop:mx-2 text-gray-700 text-xs font-medium block lg:text-sm"
                                ><span class="sr-only">Open user menu for </span
                                >{{ name }}</span
                            >
                            <!-- Heroicon name: solid/chevron-down -->
                            <svg
                                class=" mx-3 h-7 w-7 text-gray-400 block"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </button>
                    </div>
                    <!--
                      Profile dropdown panel, show/hide based on dropdown state.

                      Entering: "transition ease-out duration-100"
                        From: "transform opacity-0 scale-95"
                        To: "transform opacity-100 scale-100"
                      Leaving: "transition ease-in duration-75"
                        From: "transform opacity-100 scale-100"
                        To: "transform opacity-0 scale-95"
                    -->
                    <div
                        v-if="dropMenu === true"
                        class="origin-top-right right-0 absolute desktop:right-7 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="user-menu"
                    >
                        <a
                            v-if="this.user.role === 1"
                            @click="
                                this.$router.push({ name: 'Topup_Approvals' })
                            "
                            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-t-md"
                            role="menuitem"
                            >{{ $t("nav.payment_received") }}</a
                        >
                        <a
                            @click="this.$router.push({ name: 'Support' })"
                            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                            >{{ $t("nav.support") }}</a
                        >
                        <a
                            @click="logout"
                            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-b-md"
                            role="menuitem"
                            >{{ $t("nav.logout") }}</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HeaderSearch",
    data() {
        return {
            dropMenu: false,
            searchTerm: ""
        }
    },
    watch: {
        $route(to, from) {
            this.dropMenu = false
        }
    },
    props: {
        name: { required: true, type: String },
        picture: { required: false, type: String },
        sticky: { required: true, type: Boolean },
        user: { required: false }
    },
    methods: {
        logout() {
            this.$auth.logout({ returnTo: window.location.origin })
        },
        isHome() {
            if (this.$route.name === "Dashboard") {
                return true
            }
        },
        clearInput() {
            this.$refs.search.reset()
            this.searchTerm = ""
            if (this.$route.name === "Bookings") {
                this.$router.push("bookings")
            }
        },
        search() {
            if (this.searchTerm !== "") {
                this.$router.push({
                    name: "Bookings",
                    params: { searchTerm: this.searchTerm, isSearch: true }
                })
            }
            if (this.searchTerm === "") {
                this.$router.push("bookings")
            }
        }
    }
}
</script>

<style scoped></style>
