<template>
    <div class="lg:h-screen lg:flex">
        <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
        <transition
            enter-active-class="transition-opacity ease-linear duration-300"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity ease-linear duration-300"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="isSidebar"
                class="fixed inset-0 flex z-40 lg:hidden"
                role="dialog"
                aria-modal="true"
            >
                <transition
                    enter-active-class="transition-opacity ease-linear duration-200"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="transition-opacity ease-linear duration-200"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                    ><div
                        v-if="isSidebar"
                        class="fixed inset-0 bg-gray-600 bg-opacity-75"
                        aria-hidden="true"
                    ></div
                ></transition>

                <transition
                    enter-active-class="transition ease-in-out duration-300 transform"
                    enter-from-class="-translate-x-full"
                    enter-to-class="translate-x-0"
                    leave-active-class="transition ease-in-out duration-300 transform"
                    leave-from-class="translate-x-0"
                    leave-to-class="-translate-x-full"
                >
                    <div
                        v-if="isSidebar"
                        class="
                            relative
                            z-auto
                            flex flex-col
                            w-full
                            bg-gray-800
                        "
                    >
                        <transition
                            enter-active-class="ease-in-out duration-300"
                            enter-from-class="opacity-0"
                            enter-to-class="opacity-100"
                            leave-active-class="ease-in-out duration-300"
                            leave-from-class="opacity-100"
                            leave-to-class="opacity-0"
                        >
                            <div
                                v-if="isSidebar"
                                class="absolute top-0 right-0 -mr-12 pt-2"
                            >
                                <button
                                    type="button"
                                    @click="isSidebar = false"
                                    class="
                                        ml-1
                                        flex
                                        items-center
                                        justify-center
                                        h-10
                                        w-10
                                        rounded-full
                                        focus:outline-none
                                        focus:ring-2
                                        focus:ring-inset
                                        focus:ring-white
                                    "
                                >
                                    <span class="sr-only">Close sidebar</span>
                                    <!-- Heroicon name: outline/x -->
                                    <svg
                                        class="h-6 w-6 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </transition>
                        <div class="flex-1 h-0 pb-4 overflow-y-auto">
                            <div
                                class="relative bg-cyan-900 rounded-3xl py-8 mb-10"
                            >
                                <button
                                    type="button"
                                    @click="isSidebar = false"
                                    class="
                                        absolute
                                        right-3
                                        top-3
                                        h-8
                                        w-8
                                        focus:outline-none
                                    "
                                >
                                    <span class="sr-only">Close sidebar</span>
                                    <!-- Heroicon name: outline/x -->
                                    <svg
                                        class="h-6 w-6 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                                <div class="flex items-center">
                                    <div class="flex-1">
                                        <div
                                            class="justify-center items-center flex overflow-hidden rounded-full bg-white w-14 h-14 ml-8 mr-1"
                                        >
                                            <img :src="cLogo" />
                                        </div>
                                    </div>
                                    <div
                                        class="text-white text-xl font-bold mx-3"
                                    >
                                        {{ name }}
                                    </div>
                                    <div class="flex-1">
                                        <button
                                            class="ml-2 mt-2"
                                            @click="
                                                this.user.role === 1
                                                    ? getData()
                                                    : ''
                                            "
                                        >
                                            <svg
                                                v-if="this.user.role === 1"
                                                class="flex-shrink-0 h-8 w-8 text-gray-100 block"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                    clip-rule="evenodd"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div class="flex mt-4 justify-center">
                                    <select
                                        id="enterprise_list"
                                        ref="enterprise_list"
                                        @change="switchEnterprise"
                                        v-if="!isLoading"
                                        class="flex justify-end flex-1 mx-8 rounded-md text-center truncate"
                                        ><option
                                            v-for="(enterprise,
                                            index) in enterpriseList"
                                            :value="enterprise.enterprise_id"
                                            :key="index"
                                            :selected="
                                                enterprise.enterprise_id ===
                                                    this.selectedID
                                            "
                                        >
                                            {{ enterprise.enterprise }}
                                        </option></select
                                    >
                                </div>
                            </div>
                            <nav class="mt-5 px-2 space-y-1">
                                <div>
                                    <NavButton
                                        @click="isSidebar = false"
                                        :title="$t('nav.dashboard')"
                                        :pageName="'dashboard'"
                                        ><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="icon stroke-current icon-tabler icon-tabler-dashboard"
                                            width="36"
                                            height="36"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                            />
                                            <circle cx="12" cy="13" r="2" />
                                            <line
                                                x1="13.45"
                                                y1="11.55"
                                                x2="15.5"
                                                y2="9.5"
                                            />
                                            <path
                                                d="M6.4 20a9 9 0 1 1 11.2 0z"
                                            /></svg
                                    ></NavButton>
                                    <!--                    <NavButton-->
                                    <!--                        :title="$t('nav.approvals')"-->
                                    <!--                        :pageName="'approvals'"-->
                                    <!--                        :pendingCount="pendingCount"-->
                                    <!--                    />-->
                                    <NavButton
                                        @click="isSidebar = false"
                                        :title="$t('nav.bookings')"
                                        :pageName="'bookings'"
                                        ><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="icon stroke-current icon-tabler icon-tabler-list-search"
                                            width="36"
                                            height="36"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                            />
                                            <circle cx="15" cy="15" r="4" />
                                            <path d="M18.5 18.5l2.5 2.5" />
                                            <path d="M4 6h16" />
                                            <path d="M4 12h4" />
                                            <path d="M4 18h4" /></svg
                                    ></NavButton>
                                    <NavButton
                                        @click="isSidebar = false"
                                        :title="$t('nav.billing')"
                                        :pageName="'topup'"
                                        ><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="icon stroke-current icon-tabler icon-tabler-receipt-2"
                                            width="36"
                                            height="36"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                            />
                                            <path
                                                d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2"
                                            />
                                            <path
                                                d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5m2 0v1.5m0 -9v1.5"
                                            /></svg
                                    ></NavButton>
                                    <NavButton
                                        @click="isSidebar = false"
                                        :title="$t('nav.users')"
                                        :pageName="'users'"
                                        ><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="icon stroke-current icon-tabler icon-tabler-users"
                                            width="36"
                                            height="36"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                            />
                                            <circle cx="9" cy="7" r="4" />
                                            <path
                                                d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"
                                            />
                                            <path
                                                d="M16 3.13a4 4 0 0 1 0 7.75"
                                            />
                                            <path
                                                d="M21 21v-2a4 4 0 0 0 -3 -3.85"
                                            /></svg
                                    ></NavButton>
                                    <NavButton
                                        @click="isSidebar = false"
                                        :title="$t('nav.settings')"
                                        :pageName="'setting'"
                                        ><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="icon stroke-current icon-tabler icon-tabler-settings"
                                            width="36"
                                            height="36"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                            />
                                            <path
                                                d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"
                                            />
                                            <circle
                                                cx="12"
                                                cy="12"
                                                r="3"
                                            /></svg
                                    ></NavButton>
                                </div>
                            </nav>
                        </div>
                        <div class="flex-shrink-0 flex bg-gray-700 p-4">
                            <a class="flex-shrink-0 group block">
                                <div class="flex items-center">
                                    <div>
                                        <img
                                            class="
                                                inline-block
                                                h-10
                                                w-10
                                                rounded-full
                                            "
                                            :src="this.$auth.user.value.picture"
                                            alt=""
                                        />
                                    </div>
                                    <div class="ml-3">
                                        <p
                                            class="
                                                text-base
                                                font-medium
                                                text-white
                                            "
                                        >
                                            {{
                                                this.$auth.user.value.nickname.toUpperCase()
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </transition>
                <!--                <div class="flex-shrink-0 w-14">-->
                <!--                    &lt;!&ndash; Force sidebar to shrink to fit close icon &ndash;&gt;-->
                <!--                </div>-->
            </div>
        </transition>
        <!-- Static sidebar for desktop -->
        <div class="hidden lg:flex lg:flex-shrink-0">
            <div class="flex flex-col">
                <!-- Sidebar component, swap this element with another sidebar if you like -->
                <div
                    class="
                        flex flex-col
                        h-0
                        flex-1
                        bg-gray-800
                        w-auto
                        lg:w-64
                        desktop:w-80
                    "
                >
                    <div class="flex-1 flex flex-col pb-4 overflow-y-auto">
                        <div class="bg-cyan-900 rounded-3xl py-8 mb-10">
                            <div class="flex items-center">
                                <div class="flex-1">
                                    <div
                                        class="justify-center items-center flex overflow-hidden rounded-full bg-white w-14 h-14 ml-4 mr-1"
                                    >
                                        <img :src="cLogo" />
                                    </div>
                                </div>
                                <div class="text-white text-xl font-bold mx-3">
                                    {{ name }}
                                </div>
                                <div class="flex-1">
                                    <button
                                        class="ml-2 mt-2"
                                        @click="
                                            this.user.role === 1
                                                ? getData()
                                                : ''
                                        "
                                    >
                                        <svg
                                            v-if="this.user.role === 1"
                                            class="flex-shrink-0 h-8 w-8 text-gray-100 block"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div class="flex mt-4 justify-center">
                                <select
                                    id="enterprise_list"
                                    ref="enterprise_list"
                                    @change="switchEnterprise"
                                    v-if="!isLoading"
                                    class="flex justify-end flex-1 mx-8 rounded-md text-center truncate"
                                >
                                    <option
                                        v-for="(enterprise,
                                        index) in enterpriseList"
                                        :value="enterprise.enterprise_id"
                                        :key="index"
                                        :selected="
                                            enterprise.enterprise_id ===
                                                this.selectedID
                                        "
                                    >
                                        {{ enterprise.enterprise }}
                                    </option></select
                                >
                            </div>
                        </div>
                        <nav class="mt-5 flex-1 px-2 space-y-1">
                            <div>
                                <NavButton
                                    :title="$t('nav.dashboard')"
                                    :pageName="'dashboard'"
                                    ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon stroke-current icon-tabler icon-tabler-dashboard"
                                        width="36"
                                        height="36"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                        />
                                        <circle cx="12" cy="13" r="2" />
                                        <line
                                            x1="13.45"
                                            y1="11.55"
                                            x2="15.5"
                                            y2="9.5"
                                        />
                                        <path
                                            d="M6.4 20a9 9 0 1 1 11.2 0z"
                                        /></svg
                                ></NavButton>
                                <!--                    <NavButton-->
                                <!--                        :title="$t('nav.approvals')"-->
                                <!--                        :pageName="'approvals'"-->
                                <!--                        :pendingCount="pendingCount"-->
                                <!--                    />-->
                                <NavButton
                                    :title="$t('nav.bookings')"
                                    :pageName="'bookings'"
                                    ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon stroke-current icon-tabler icon-tabler-list-search"
                                        width="36"
                                        height="36"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                        />
                                        <circle cx="15" cy="15" r="4" />
                                        <path d="M18.5 18.5l2.5 2.5" />
                                        <path d="M4 6h16" />
                                        <path d="M4 12h4" />
                                        <path d="M4 18h4" /></svg
                                ></NavButton>
                                <NavButton
                                    :title="$t('nav.billing')"
                                    :pageName="'topup'"
                                    ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon stroke-current icon-tabler icon-tabler-receipt-2"
                                        width="36"
                                        height="36"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                        />
                                        <path
                                            d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2"
                                        />
                                        <path
                                            d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5m2 0v1.5m0 -9v1.5"
                                        /></svg
                                ></NavButton>
                                <NavButton
                                    :title="$t('nav.users')"
                                    :pageName="'users'"
                                    ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon stroke-current icon-tabler icon-tabler-users"
                                        width="36"
                                        height="36"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                        />
                                        <circle cx="9" cy="7" r="4" />
                                        <path
                                            d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"
                                        />
                                        <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                        <path
                                            d="M21 21v-2a4 4 0 0 0 -3 -3.85"
                                        /></svg
                                ></NavButton>
                                <NavButton
                                    :title="$t('nav.settings')"
                                    :pageName="'setting'"
                                    ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon stroke-current icon-tabler icon-tabler-settings"
                                        width="36"
                                        height="36"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                        />
                                        <path
                                            d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"
                                        />
                                        <circle cx="12" cy="12" r="3" /></svg
                                ></NavButton>
                            </div>
                        </nav>
                    </div>
                    <div class="flex-shrink-0 flex bg-gray-700 p-4">
                        <a class="flex-shrink-0 w-full group block">
                            <div class="flex items-center">
                                <div>
                                    <img
                                        class="
                                            inline-block
                                            h-9
                                            w-9
                                            rounded-full
                                        "
                                        :src="this.$auth.user.value.picture"
                                        alt=""
                                    />
                                </div>
                                <div class="ml-3">
                                    <p class="text-sm font-medium text-white">
                                        {{
                                            this.$auth.user.value.nickname.toUpperCase()
                                        }}
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <transition
            enter-active-class="transition-opacity ease-linear duration-300"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity ease-linear duration-300"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-if="!isSidebar"
                class="lg:hidden lg:pl-64 flex flex-col flex-1"
            >
                <div
                    class="
                        sticky
                        w-screen
                        flex
                        justify-between
                        items-center
                        top-0
                        z-40
                        lg:hidden
                        pt-2
                        sm:pl-3 sm:pt-3
                        bg-gray-700 bg-opacity-90
                    "
                >
                    <div class="flex flex-1">
                        <button
                            type="button"
                            @click="isSidebar = true"
                            class="
                                ml-2
                                mb-1
                                inline-flex
                                items-center
                                justify-center
                                rounded-md
                                text-white
                                focus:outline-none
                            "
                        >
                            <span class="sr-only">Open sidebar</span>
                            <!-- Heroicon name: outline/menu -->
                            <svg
                                class="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>
                        </button>
                    </div>
                    <div class="flex justify-center flex-1">
                        <img
                            class="h-auto w-16 mb-2"
                            alt="flow logo"
                            src="https://res.cloudinary.com/flowtheroom/image/upload/v1618154583/icon/flow_s_light_g7vaij.png"
                        />
                    </div>
                    <div class="flex-1"></div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import NavButton from "@/components/NavButton"
import { Locale } from "@/constants"
import { setSavedLocale } from "@/util/locate"

export default {
    name: "Sidebar",
    setup() {},
    components: { NavButton },
    emits: ["onListUpdate"],
    props: {
        companyName: { required: true, type: String },
        companyLogo: { required: true, type: String },
        user: { required: false }
        // pendingCount: Number
    },
    data() {
        return {
            enterpriseList: [],
            isSidebar: false,
            selectedID: -1,
            name: this.companyName,
            cLogo: this.companyLogo,
            isLoading: true
        }
    },
    computed: {
        locales() {
            return Locale.SUPPORTED_LOCALES
        }
    },
    methods: {
        async getData() {
            if (this.isLoading) {
                const token = await this.$auth.getTokenSilently()
                const url =
                    process.env.VUE_APP_API_BASE_URL +
                    `/index/enterprise/list?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}`

                this.$axios
                    .get(url, {
                        headers: {
                            Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                        }
                    })
                    .then(response => {
                        this.enterpriseList = response.data.Data.list
                        this.isLoading = false
                    })
            } else this.isLoading = true
        },
        switchEnterprise() {
            this.selectedID = parseInt(this.$refs.enterprise_list.value)
            this.$emit("onListUpdate", this.selectedID)
            this.name = this.enterpriseList.filter(
                i => i.enterprise_id === this.selectedID
            )[0].enterprise
            this.cLogo = this.enterpriseList.filter(
                i => i.enterprise_id === this.selectedID
            )[0].icon
            setTimeout(() => {
                this.isLoading = true
            }, 400)
        }
    }
}
</script>

<style scoped></style>
