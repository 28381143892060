<template>
    <div
        v-if="isLoading"
        class="flex items-center bg-gray-100 w-screen h-screen bg-cover"
    >
        <div class="w-full h-full flex justify-center items-center">
            <div
                class="
                    absolute
                    h-16
                    w-16
                    border-t-4 border-b-4 border-yellow-400
                    rounded-full
                    animate-spin
                "
            ></div>
            <div
                class="
                    h-16
                    w-16
                    border-r-2 border-l-2 border-gray-200
                    rounded-full
                    animate-spin
                "
            ></div>
        </div>
    </div>
    <div v-if="!isLoading && isValid" class="flex flex-row w-full h-full">
        <div class="sticky w-0 lg:w-auto h-screen top-0 z-30">
            <Sidebar
                :user="data"
                :enterprise="enterpriseID"
                @onListUpdate="changeEnterprise"
                :companyName="data.enterprise"
                :companyLogo="data.icon"
            />
        </div>
        <div
            class="w-full min-h-screen h-full bg-gray-100 pt-8 lg:pt-0"
            :class="{ 'overflow-hidden': isLocked, 'h-screen': isLocked }"
        >
            <div ref="header" class="lg:sticky z-10 lg:-top-1">
                <HeaderSearch
                    :user="data"
                    :name="data.given_name + ' ' + data.surname"
                    :sticky="sticky"
                />
            </div>
            <router-view
                :user="data"
                :enterprise="enterpriseID"
                @updateSetting="getData"
                @disableScroll="disableScroll"
                @enableScroll="enableScroll"
            />
        </div>
    </div>
</template>

<script>
import Sidebar from "@/components/Sidebar"
import HeaderSearch from "@/components/HeaderSearch"
import Spinner from "@/components/Spinner"

export default {
    name: "Home",
    components: { HeaderSearch, Sidebar, Spinner },
    data() {
        return {
            isLoading: true,
            sticky: true,
            isValid: false,
            pending: null,
            isLocked: false,
            enterpriseID: -1,
            data: ""
        }
    },
    mounted() {
        if (this.$auth.isAuthenticated.value === true) {
            this.getData()
            // this.getPendingCount()
            // setInterval(() => {
            //     this.getPendingCount()
            // }, 60000)
        }
    },
    updated() {
        if (this.isValid) {
            this.stickyHeader()
        }
    },
    watch: {
        $route(to, from) {
            this.enableScroll()
        }
    },
    methods: {
        stickyHeader() {
            let options = {
                root: null, // Can be any other element
                threshold: 1 // Ranges from 0.1 to 1.0 or an array of values
            }
            let callback = (entries, observer) => {
                entries.forEach(entry => {
                    if (this.$route.name === "Dashboard") {
                        this.sticky = entry.isIntersecting
                    } else this.sticky = false
                })
            }
            let observer = new IntersectionObserver(callback, options)
            observer.observe(this.$refs.header)
        },
        async getData() {
            const token = await this.$auth.getTokenSilently()
            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/index/login?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}`
            this.$axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                    }
                })
                .then(response => {
                    if (response.data.Success) {
                        setTimeout(() => {
                            this.data = response.data.Data
                            this.enterpriseID = response.data.Data.enterprise_id
                            this.isValid = true
                            this.isLoading = false
                        }, 1500)
                    } else {
                        this.data = response.data.Data
                        this.isLoading = false
                        if (this.data.error_id) {
                            alert(this.data.error_id + "\n" + this.data.message)
                        }
                        this.$auth.logout({ returnTo: window.location.origin })
                    }
                })
        },
        // async getPendingCount() {
        //     const token = await this.$auth.getTokenSilently()
        //     const url =
        //         process.env.VUE_APP_API_BASE_URL +
        //         `/index/menu?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}`
        //     this.$axios
        //         .get(url, {
        //             headers: {
        //                 Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
        //             }
        //         })
        //         .then(res => {
        //             this.pending = res.data.Data.pending
        //         })
        // },
        disableScroll() {
            this.isLocked = true
        },
        enableScroll() {
            this.isLocked = false
        },
        changeEnterprise(id) {
            this.enterpriseID = id
        }
    }
}
</script>

<style scoped lang="css">
@keyframes blinkdot {
    0% {
        opacity: 0.2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}
.loading span {
    animation-name: blinkdot;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}
.loading span:nth-child(2) {
    animation-delay: 0.2s;
}
.loading span:nth-child(3) {
    animation-delay: 0.4s;
}
</style>
